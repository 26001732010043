/* eslint-disable no-nested-ternary */
/* eslint-disable unicorn/prevent-abbreviations */
// Path: packages/flex/src/datepicker/datepicker.tsx

import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  startOfMonth,
  addDays,
  getDay,
  subDays,
  getMonth,
  isSameMonth,
  isToday,
  format,
  isWithinInterval,
  isSameDay,
  addMonths,
  subMonths,
  endOfMonth,
  isBefore,
  isAfter,
  isValid,
  getYear,
} from 'date-fns';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import * as PortalPrimitive from '@radix-ui/react-portal';
import { Input } from '../Input';
import { Text } from '../Text';
import type { DatePickerProperties, TDatePickerTranslationKeys } from './datepicker.types';
import { Button } from '../Button';
import { defaultTranslationKeys } from './datepicker.constants';

enum Presets {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
}

const applyDatePreset = (preset: Presets) => {
  const today = new Date();
  switch (preset) {
    case 'today':
      return [today, today];
    case 'yesterday':
      return [subDays(today, 1), subDays(today, 1)];
    case 'last7Days':
      return [subDays(today, 7), subDays(today, 1)];
    case 'last30Days':
      return [subDays(today, 31), subDays(today, 1)];
    case 'thisMonth':
      return [startOfMonth(today), endOfMonth(today)];
    case 'lastMonth':
      return [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))];

    default:
      return [today, today];
  }
};

function createDatePickerArray(date: Date) {
  const datePickerArray = [];
  const currentMonth = getMonth(date);
  let currentDate = startOfMonth(date);

  // create array of days in current month
  while (currentMonth === getMonth(currentDate)) {
    datePickerArray.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }

  // add days from previous month to fill out first week
  let firstDay = datePickerArray[0];
  while (getDay(firstDay) !== 1) {
    firstDay = subDays(firstDay, 1);
    datePickerArray.unshift(firstDay);
  }

  // add days from next month to fill out last week
  let lastDay = datePickerArray[datePickerArray.length - 1];
  while (getDay(lastDay ?? new Date()) !== 0) {
    lastDay = addDays(lastDay ?? new Date(), 1);
    datePickerArray.push(lastDay);
  }

  // create sub arrays of 7 days each
  const weeks = [];
  while (datePickerArray.length > 0) {
    weeks.push(datePickerArray.splice(0, 7));
  }

  return weeks;
}

const Calendar = ({
  onDateSelection,
  defaultDate = new Date(),
  isRange,
  t,
  min,
  max,
  className,
}: {
  onDateSelection: (startDate: Date, endDate?: Date) => void;
  defaultDate?: Date | Date[];
  isRange?: boolean;
  t: TDatePickerTranslationKeys;
  min?: Date;
  max?: Date;
  className?: string;
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(
    Array.isArray(defaultDate) ? defaultDate[0] : undefined,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    Array.isArray(defaultDate) ? defaultDate[1] : undefined,
  );
  const [hoverDate, setHoverDate] = useState<Date | null>();
  const [monthToDisplay, setMonthToDisplay] = useState(new Date());

  const weeks = useMemo(() => createDatePickerArray(monthToDisplay), [monthToDisplay]);

  useEffect(() => {
    // if date is a range, and both dates are set, call onDateSelection
    if (isRange && startDate && endDate && isTouched) {
      onDateSelection(
        startDate < endDate ? startDate : endDate,
        startDate < endDate ? endDate : startDate,
      );
    }
  }, [startDate, endDate]);

  return (
    <div className={className}>
      <div className="text-center">
        <div className="flex items-center text-slate-900">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-400 hover:text-slate-500"
            onClick={() => setMonthToDisplay((prev) => subMonths(prev, 1))}
          >
            <span className="sr-only">{t.previousMonth}</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <Text className="flex-auto" strong="semi">
            {`${t.months[getMonth(monthToDisplay)]} ${getYear(monthToDisplay)}`}
          </Text>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-400 hover:text-slate-500"
            onClick={() => setMonthToDisplay((prev) => addMonths(prev, 1))}
          >
            <span className="sr-only">{t.nextMonth}</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-slate-500">
          {t.days.map((day) => (
            <Text type="secondary" strong="medium" key={day}>
              {day}
            </Text>
          ))}
        </div>
        <div className="isolate mt-2 grid grid-cols-7 overflow-hidden rounded-lg bg-slate-200 text-sm shadow ring-1 ring-slate-200">
          {weeks.map((week, weekIndex) => (
            <React.Fragment key={weekIndex}>
              {week.map((day, dayIndex) => {
                const isCurrentMonth = isSameMonth(day, monthToDisplay);
                const isTodayDate = isToday(day);
                const isSelected = isRange
                  ? (startDate &&
                      endDate &&
                      isWithinInterval(day, {
                        start: startDate < endDate ? startDate : endDate,
                        end: startDate < endDate ? endDate : startDate,
                      })) ||
                    (startDate && isSameDay(day, startDate))
                  : isSameDay(day, Array.isArray(defaultDate) ? defaultDate[0] : defaultDate);
                const isInHoverRange =
                  isRange &&
                  startDate !== undefined &&
                  hoverDate &&
                  !endDate &&
                  isWithinInterval(day, {
                    start: startDate < hoverDate ? startDate : hoverDate,
                    end: startDate < hoverDate ? hoverDate : startDate,
                  });

                const isDisabled = (min && isBefore(day, min)) || (max && isAfter(day, max));

                // rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg
                const roundedCorner = () => {
                  if (weekIndex === 0) {
                    if (dayIndex === 0) return 'rounded-tl-lg';
                    if (dayIndex === 6) return 'rounded-tr-lg';
                  }

                  if (weekIndex === weeks.length - 1) {
                    if (dayIndex === 0) return 'rounded-bl-lg';
                    if (dayIndex === 6) return 'rounded-br-lg';
                  }

                  return '';
                };

                return (
                  <motion.button
                    key={day.toISOString()}
                    type="button"
                    className={clsx(
                      'group select-none border-[0.5px] border-solid border-slate-200 px-1.5 py-1.5 hover:bg-slate-100 focus:z-10',
                      (isSelected || isTodayDate) && 'font-semibold',
                      isCurrentMonth ? 'bg-white' : 'bg-slate-50',
                      isSelected && 'text-white',
                      isTodayDate && 'text-primary-400 hover:text-primary-500',
                      isInHoverRange && '!bg-brand-400 text-white',
                      isSelected &&
                        isRange &&
                        endDate &&
                        isCurrentMonth &&
                        '!bg-brand-500 text-white',
                      isSelected &&
                        isRange &&
                        endDate &&
                        !isCurrentMonth &&
                        '!bg-brand-600 text-white',
                      isSelected && isRange && !endDate && 'bg-primary-400 text-white',
                      isRange && startDate && !endDate && 'transition-colors',
                      isDisabled && 'pointer-events-none cursor-not-allowed bg-slate-50',
                      roundedCorner(),
                    )}
                    onClick={() => {
                      if (isDisabled) return;

                      if (!isRange) {
                        onDateSelection(day);
                      } else if (startDate === undefined) {
                        setStartDate(day);
                        setIsTouched(true);
                      } else if (startDate && endDate) {
                        setStartDate(day);
                        // eslint-disable-next-line unicorn/no-useless-undefined
                        setEndDate(undefined);
                        setIsTouched(true);
                      } else {
                        setEndDate(day);
                        setIsTouched(true);
                      }
                    }}
                    onMouseEnter={() => {
                      if (isRange && !isDisabled) setHoverDate(day);
                    }}
                    onMouseLeave={() => {
                      // eslint-disable-next-line unicorn/no-null
                      if (isRange && !isDisabled) setHoverDate(null);
                    }}
                  >
                    <time
                      dateTime={day.toISOString()}
                      className={clsx(
                        'mx-auto flex h-7 w-7 items-center justify-center rounded-full font-body',
                        isSelected && !isRange && isCurrentMonth && 'bg-primary-500',
                        isSelected && !isRange && isCurrentMonth && 'bg-primary-600',
                        isDisabled && 'pointer-events-none text-slate-200',
                      )}
                    >
                      {day.getDate()}
                    </time>
                  </motion.button>
                );
              })}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * DatePicker is a component that allows users to select a date, or a range of dates.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=29%3A1710&t=DDTNsNZ0BZurh04u-1
 */
export const DatePicker = ({
  type = 'date',
  onChange,
  dateInputProps,
  rangeInputProps,
  translations = defaultTranslationKeys,
  min,
  max,
  className,
  calendarClassName,
  value,
  defaultValue,
  label,
  hint,
  optional,
  name,
}: DatePickerProperties) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentPreset, setCurrentPreset] = useState<Presets | null>();
  const [rangeDisplayValue, setRangeDisplayValue] = useState<[Date, Date] | undefined>();
  const { x, y, refs, strategy } = useFloating({
    strategy: 'fixed',
    middleware: [offset(10), flip()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setIsCalendarOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleBlur = () => {
    setIsCalendarOpen(false);
    document.body.style.overflow = '';
    inputRef.current?.blur();
  };

  // escape key event listener
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') handleBlur();
    };

    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  const handleDateSelection = (
    dates: { start: Date; end?: Date },
    options?: {
      preset?: boolean;
    },
  ) => {
    if (!value) {
      if (type === 'date') {
        if (inputRef.current) inputRef.current.value = format(dates.start, 'yyyy-MM-dd');
      } else if (type === 'range' && startDateRef.current && endDateRef.current && dates.end) {
        startDateRef.current.value = format(dates.start, 'yyyy-MM-dd');
        endDateRef.current.value = format(dates.end, 'yyyy-MM-dd');
      }
    }

    // eslint-disable-next-line unicorn/no-null
    if (!options?.preset) setCurrentPreset(null);
    if (type === 'range' && dates.end) {
      setRangeDisplayValue([dates.start, dates.end]);
    }

    handleBlur();

    onChange?.(dates);
  };

  useEffect(() => {
    if (type === 'range') {
      const range = value || defaultValue;

      if (range && !(range instanceof Date) && range.end) {
        setRangeDisplayValue([range.start, range.end]);
      }
    }
  }, []);

  return (
    <div className={clsx('flex flex-col', className)}>
      <div className="space-y-2">
        <div className="space-y-1">
          <div className="flex w-full items-center justify-between gap-4 overflow-auto">
            <label htmlFor={type === 'range' ? `${name}-start` : name} aria-label={label}>
              <Text as="span" className="truncate !font-medium">
                {label}
              </Text>
            </label>

            {optional && <Text type="muted">{translations?.optional}</Text>}
          </div>
          <div ref={refs.setReference}>
            {type === 'date' ? (
              <Input
                name={name}
                type="date"
                className="w-full"
                placeholder={translations?.selectDate}
                trailingAddOn={<CalendarIcon />}
                trailingAddOnDisplay="inline"
                onFocus={handleFocus}
                onBlur={(event) => event.preventDefault()}
                onClick={() => (!isCalendarOpen ? handleFocus() : handleBlur())}
                inputRef={inputRef}
                defaultValue={
                  defaultValue instanceof Date && isValid(defaultValue)
                    ? format(defaultValue, 'yyyy-MM-dd')
                    : undefined
                }
                onKeyDown={(event) => {
                  // handle if the key is enter
                  if (event.key === 'Enter') {
                    handleDateSelection({
                      start: new Date(event.currentTarget.value),
                    });
                  }
                }}
                readOnly
                {...(value && value instanceof Date && isValid(value)
                  ? { value: format(value, 'yyyy-MM-dd') }
                  : {})}
                {...dateInputProps}
              />
            ) : (
              <>
                <input
                  type="date"
                  name={`${name}-start`}
                  hidden
                  ref={startDateRef}
                  defaultValue={
                    !(defaultValue instanceof Date) &&
                    defaultValue?.start &&
                    defaultValue?.end &&
                    isValid(defaultValue.start)
                      ? format(defaultValue.start, 'yyyy-MM-dd')
                      : undefined
                  }
                  {...(value &&
                  !(value instanceof Date) &&
                  value?.start &&
                  value?.end &&
                  isValid(value.start)
                    ? { value: format(value.start, 'yyyy-MM-dd') }
                    : {})}
                  {...rangeInputProps?.start}
                  readOnly
                />
                <input
                  type="date"
                  name={`${name}-end`}
                  hidden
                  ref={endDateRef}
                  defaultValue={
                    !(defaultValue instanceof Date) &&
                    defaultValue?.start &&
                    defaultValue?.end &&
                    isValid(defaultValue.end)
                      ? format(defaultValue.end, 'yyyy-MM-dd')
                      : undefined
                  }
                  {...(value &&
                  !(value instanceof Date) &&
                  value?.start &&
                  value?.end &&
                  isValid(value.end)
                    ? { value: format(value.end, 'yyyy-MM-dd') }
                    : {})}
                  {...rangeInputProps?.end}
                  readOnly
                />
                <Input
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  name={undefined} /* this is a hack to remove the name attribute from the input
              because we are using two inputs instead of one and we don't want
              the browser to try to validate or submit the input */
                  type="text"
                  className="w-full"
                  placeholder={translations?.selectDateRange}
                  trailingAddOn={<CalendarIcon />}
                  trailingAddOnDisplay="inline"
                  readOnly
                  {...(rangeDisplayValue
                    ? {
                        value: `${rangeDisplayValue[0].toLocaleDateString(translations.lang, {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        })} ⟶ ${rangeDisplayValue[1].toLocaleDateString(translations.lang, {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        })}`,
                      }
                    : {})}
                  onFocus={handleFocus}
                  onBlur={(event) => {
                    event.preventDefault();
                  }}
                  onClick={(event) => event.preventDefault()}
                  inputRef={inputRef}
                  key={`${startDateRef.current?.value}${endDateRef.current?.value}`}
                />
              </>
            )}
          </div>
        </div>

        {hint && <Text type="muted">{hint}</Text>}
      </div>
      <AnimatePresence>
        {isCalendarOpen && (
          <PortalPrimitive.Root>
            <PortalPrimitive.Portal className="fixed inset-0 z-[70]">
              <div
                className="fixed inset-0"
                onClick={handleBlur}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    handleBlur();
                  }
                }}
                role="button"
                aria-label="Close date picker"
                tabIndex={0}
              />
              <div
                ref={refs.setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: -2 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -2, transition: { duration: 0.1 } }}
                  transition={{ duration: 0.3, ease: 'anticipate' }}
                  className="max-w-screen w-full space-y-4 rounded-xl bg-white p-4 shadow-lg md:max-w-sm"
                >
                  <Calendar
                    onDateSelection={(start, end) =>
                      handleDateSelection({
                        start,
                        end,
                      })
                    }
                    defaultDate={
                      type === 'date'
                        ? inputRef.current?.value
                          ? new Date(inputRef.current?.value)
                          : defaultValue instanceof Date && isValid(defaultValue)
                          ? defaultValue
                          : undefined
                        : startDateRef.current?.value && endDateRef.current?.value
                        ? [
                            new Date(startDateRef.current?.value),
                            new Date(endDateRef.current?.value),
                          ]
                        : !(defaultValue instanceof Date) &&
                          defaultValue?.start &&
                          defaultValue?.end &&
                          isValid(defaultValue.start) &&
                          isValid(defaultValue.end)
                        ? [defaultValue.start, defaultValue.end]
                        : undefined
                    }
                    isRange={type === 'range'}
                    t={translations}
                    min={min}
                    max={max}
                    className={calendarClassName}
                  />

                  {type === 'range' && (
                    <div className="flex flex-wrap gap-2">
                      {Object.values(Presets).map((preset) => {
                        const [start, end] = applyDatePreset(preset);
                        const disabled = (min && start < min) || (max && end > max);

                        return (
                          <Button
                            key={preset}
                            theme={preset === currentPreset ? 'primary' : 'white'}
                            onClick={() => {
                              const [start, end] = applyDatePreset(preset);

                              setCurrentPreset(preset);
                              handleDateSelection({ start, end }, { preset: true });
                            }}
                            size="xs"
                            disabled={disabled}
                          >
                            {translations[preset]}
                          </Button>
                        );
                      })}
                    </div>
                  )}
                </motion.div>
              </div>
            </PortalPrimitive.Portal>
          </PortalPrimitive.Root>
        )}
      </AnimatePresence>
    </div>
  );
};
