import React from 'react';
import { BsCart, BsQuestionCircle, BsPerson, BsGear } from 'react-icons/bs';

/** @namespace AdditionalNavigation */
const AdditionalNavigation = {
  admin: (admin) => admin,
  nav: (nav) => nav,
  actions: (actions) => actions,
};

const statisticsPages = {
  i18n_value: 'statistics',
  path: '/statistics',
  active_paths: ['/statistiques', '/statistics'],
  featuresOR: ['social_stats', 'stats', 'paidmedia'],
  children: [
    {
      i18n_value: 'statistics_engagement.main',
      path: '/statistics/engagement',
      feature: 'stats',
      permission: 'hyp.stats.list',
      children: [
        {
          i18n_value: 'statistics_engagement.dashboard',
          path: '/statistics/engagement',
          permission: 'hyp.stats.list',
        },
        {
          i18n_value: 'statistics_engagement.salespoints',
          path: '/statistics/engagement/salespoints',
          permission: 'hyp.stats.list',
        },
        {
          i18n_value: 'statistics_engagement.users',
          path: '/statistics/engagement/users',
          permission: 'hyp.stats.list',
        },
      ],
    },
    {
      i18n_value: 'admin_establishments_presence_mgmt',
      path: '/statistics/presence-managment',
      featuresAND: ['stats', 'presence_management'],
      permission: 'hyp.pm.stats',
    },
    {
      i18n_value: 'admin_campaigns',
      path: '/statistics/campaign',
      featuresAND: ['stats', 'campaigns'],
      permission: 'hyp.campaigns.stats',
    },
    {
      i18n_value: 'statistics_shop',
      path: '/statistics/shop',
      featuresAND: ['stats', 'shop'],
      permission: 'hyp.shop.stats',
    },
    {
      i18n_value: 'statistics_downloads',
      path: '/statistics/downloads',
      feature: 'stats',
      permission: 'hyp.stats.list',
    },
    {
      i18n_value: 'statistics_library',
      path: '/statistics/library',
      featuresAND: ['stats', 'library'],
      permission: 'hyp.library.stats',
    },
    {
      i18n_value: 'statistics_paidmedia',
      path: '/statistics/paidmedia',
      feature: 'paidmedia',
    },
    {
      // It's important to keep it last
      // because it redirect to another tab
      i18n_value: 'statistics_social',
      path: '/social/statistics',
      feature: 'social_stats',
      permission: 'hyp.social.analyze.list',
    },
  ],
};

export const navigation = AdditionalNavigation.nav([
  {
    i18n_value: 'campaigns',
    path: '/planning?view=list',
    permission: 'hyp.campaigns.list',
    active_paths: ['/plan-de-communication', '/campagnes', '/campaigns', 'planning'],
    feature: 'campaigns',
  },
  {
    i18n_value: 'planning',
    path: '/planning?view=calendar',
    permission: 'hyp.campaigns.list',
    active_paths: ['/plan-de-communication', '/campagnes', '/campaigns', 'planning'],
    feature: 'campaigns',
  },
  {
    i18n_value: 'briefs',
    path: '/briefs',
    permission: 'hyp.briefs.list',
    active_paths: ['/briefs'],
    feature: 'briefs',
  },
  {
    i18n_value: 'library',
    path: '/library',
    permission: 'hyp.library.list',
    active_paths: ['/bibliotheque', '/add-folder', '/add-files', '/update-file', '/update-folder'],
    feature: 'library',
  },
  {
    i18n_value: 'shop',
    path: '/catalog',
    permission: 'hyp.shop.list',
    active_paths: ['/catalogue'],
    feature: 'shop',
  },
  {
    i18n_value: 'social',
    path: '/social',
    active_paths: ['/social'],
    children: [
      {
        i18n_value: 'social_publications_list',
        feature: 'social_pub',
        permission: 'hyp.social.publish.list',
        path: '/social/publications',
      },
      {
        i18n_value: 'social_publications_templates',
        feature: 'social_pub',
        permission: 'hyp.social.publish.templates.list',
        path: '/social/templates',
      },
      {
        i18n_value: 'social_amplify',
        path: '/social/amplify',
        permission: 'hyp.social.amplify.list',
        feature: 'social_ads',
      },
      {
        i18n_value: 'social_messaging',
        path: '/social/messaging',
        permission: 'hyp.social.converse.list',
        feature: 'social_msg',
      },
      {
        i18n_value: 'social_statistics',
        path: '/social/statistics',
        permission: 'hyp.social.analyze.list',
        feature: 'social_stats',
      },
    ],
  },
  statisticsPages,
  {
    i18n_value: 'direct_marketing',
    path: '/direct_marketing',
    active_paths: [],
    permission: 'hyp.direct-marketing.access',
    feature: 'direct_marketing',
  },
  {
    i18n_value: 'provider',
    path: '/provider',
    active_paths: [],
    userRole: ['provider', 'provider-admin'],
    permission: 'hyp.bo-provider.access',
    children: [
      {
        i18n_value: 'provider_orders_to_be_processed',
        path: '/provider/orders-to-be-processed',
        permission: 'hyp.bo-provider.access',
        providerType: 'mkp',
        userRole: ['provider', 'provider-admin'],
      },
      {
        i18n_value: 'provider_completed_orders',
        path: '/provider/completed-orders',
        permission: 'hyp.bo-provider.access',
        providerType: 'mkp',
        userRole: ['provider', 'provider-admin'],
      },
      {
        i18n_value: 'briefs',
        path: '/provider/briefs',
        permission: 'hyp.briefs.list',
        providerType: 'brief',
        active_paths: ['/provider/briefs'],
        userRole: ['provider', 'provider-admin'],
      },
      {
        i18n_value: 'provider_orders_export',
        path: '/provider/orders-export',
        permission: 'hyp.bo-provider.access',
        providerType: 'mkp',
        userRole: ['provider', 'provider-admin'],
      },
      {
        i18n_value: 'provider_shipping_fees',
        path: '/provider/shipping-fees',
        permission: 'hyp.orders.manage.shippingfees',
        providerType: 'mkp',
        userRole: ['provider-admin'],
      },
      {
        i18n_value: 'admin_users',
        path: '/provider/users',
        active_paths: ['/provider/users'],
        permission: 'hyp.bo-provider.access',
        userRole: ['provider-admin'],
        children: [
          {
            i18n_value: 'admin_users_list',
            path: '/provider/users',
            userRole: ['provider-admin'],
            permission: 'hyp.users.manage',
          },
          {
            i18n_value: 'admin_permissions',
            path: '/provider/permissions',
            userRole: ['provider-admin'],
            permission: 'hyp.users.permissions',
          },
        ],
      },
      {
        i18n_value: 'api_keys',
        permission: 'hyp.bo-provider.access',
        userRole: ['provider-admin'],
        path: '/provider/api',
      },
      {
        i18n_value: 'provider_personal_informations',
        path: '/provider/personal-informations',
        userRole: ['provider', 'provider-admin'],
      },
    ],
  },
  {
    i18n_value: 'super_admin',
    path: '/super-admin/organizations',
    active_paths: ['/super-admin'],
    userRole: ['super-admin'],
    permission: 'hyp.super-admin.all',
    children: [
      {
        i18n_value: 'super_admin_organizations',
        path: '/super-admin/organizations',
        permission: 'hyp.super-admin.all',
        userRole: ['super-admin'],
      },
      {
        i18n_value: 'super_admin_providers',
        path: '/super-admin/providers',
        permission: 'hyp.super-admin.all',
        userRole: ['super-admin'],
      },
      {
        i18n_value: 'super_admin_news',
        path: '/super-admin/news',
        permission: 'hyp.super-admin.all',
        userRole: ['super-admin'],
      },
      {
        i18n_value: 'super_admin_tools',
        permission: 'hyp.super-admin.all',
        userRole: ['super-admin'],
        children: [
          {
            i18n_value: 'super_admin_tools_establishments',
            path: '/super-admin/tools/establishments',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_tools_error_posts',
            path: '/super-admin/tools/error-posts',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_tools_crons',
            path: '/super-admin/tools/social-crons',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_tools_sso',
            path: '/super-admin/tools/sso-list',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_staci',
            path: '/super-admin/tools/staci',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_tools_sales',
            path: '/super-admin/tools/sales',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_tools_users',
            path: '/super-admin/tools/users',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_tools_webhook',
            path: '/super-admin/tools/webhook',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
          {
            i18n_value: 'super_admin_tools_answer_templates',
            path: '/super-admin/tools/answer-templates',
            permission: 'hyp.super-admin.all',
            userRole: ['super-admin'],
          },
        ],
      },
    ],
  },
]);

const adminAction = {
  i18n_value: 'admin',
  path: '/admin',
  active_paths: ['/admin'],
  feature: 'admin',
  hide_on_xs: true,
  icon: <BsGear className="text-xl" />,
  children: AdditionalNavigation.admin([
    {
      i18n_value: 'admin_general',
      path: '/admin/general',
      children: [
        {
          i18n_value: 'admin_general_homepage',
          permission: 'hyp.platform.manage.homepage',
          feature: 'homepage',
          path: '/admin/general/homepage-settings',
        },
        {
          i18n_value: 'admin_general_logoandcolors',
          permission: 'hyp.platform.manage.org',
          path: '/admin/general/logo-colors-settings',
        },
        {
          i18n_value: 'admin_general_chilipublisher',
          permission: 'hyp.platform.manage.chili',
          path: '/admin/general/chilipublisher-settings',
        },
        {
          i18n_value: 'admin_general_help',
          permission: 'hyp.platform.manage.help',
          path: '/admin/general/help',
        },
        {
          i18n_value: 'api_keys',
          permission: 'hyp.platform.manage',
          path: '/admin/general/api',
        },
        {
          i18n_value: 'admin_sso',
          feature: 'hyperspread_sso_debugger',
          path: '/admin/general/sso',
        },
      ],
    },
    {
      i18n_value: 'admin_users',
      path: '/admin/users',
      children: [
        {
          i18n_value: 'admin_users_list',
          path: '/admin/users',
          permission: 'hyp.users.manage',
        },
        {
          i18n_value: 'admin_permissions',
          path: '/admin/users/permissions',
          permission: 'hyp.users.permissions',
        },
        {
          i18n_value: 'admin_moderation',
          path: '/admin/users/moderation',
          permission: 'hyp.users.moderation',
          feature: 'governance',
          featuresOR: ['social_pub', 'social_msg', 'shop' /* , 'social_ads' */],
        },
      ],
    },
    {
      i18n_value: 'admin_establishments',
      path: '/admin/establishments',
      children: [
        {
          i18n_value: 'user_profile_stores',
          path: '/admin/establishments',
          permission: 'hyp.salespoints.manage',
        },
        {
          i18n_value: 'admin_establishments_groups',
          permission: 'hyp.salespointsgroups.manage',
          path: '/admin/establishments/groups',
        },
        {
          i18n_value: 'admin_establishments_presence_mgmt',
          permission: 'hyp.salespoints.manage',
          path: '/admin/establishments/presence-management',
        },
        {
          i18n_value: 'admin_custom_fields',
          permission: 'hyp.salespoints.manage',
          path: '/admin/establishments/custom-fields',
        },
      ],
    },
    {
      i18n_value: 'admin_campaigns',
      feature: 'campaigns',
      path: '/admin/campaigns',
      children: [
        {
          i18n_value: 'admin_list',
          feature: 'campaigns',
          path: '/admin/campaigns',
          permissionsOR: [
            'hyp.campaigns.manage.national.all',
            'hyp.campaigns.manage.national.self',
            'hyp.campaigns.manage.local.all',
            'hyp.campaigns.manage.local.self',
          ],
        },
        {
          i18n_value: 'admin_category',
          feature: 'campaigns',
          path: '/admin/campaigns/categories',
          permission: 'hyp.campaigns.manage.categories',
        },
      ],
    },
    {
      i18n_value: 'admin_tags',
      featuresOR: ['library', 'shop'],
      path: '/admin/library',
      children: [
        {
          i18n_value: 'admin_tags_list',
          featuresOR: ['library', 'shop'],
          path: '/admin/tags',
          permission: 'hyp.tag.manage',
        },
        {
          i18n_value: 'admin_tag_groups',
          featuresOR: ['library', 'shop'],
          path: '/admin/tag-groups',
          permission: 'hyp.tag.manage',
        },
      ],
    },
    {
      i18n_value: 'admin_shop',
      feature: 'shop',
      permission: 'hyp.shop.manage',
      path: '/admin/shop',
      children: [
        {
          i18n_value: 'admin_shop_general',
          feature: 'shop',
          permission: 'hyp.shop.manage.settings',
          path: '/admin/shop/general',
        },
        {
          i18n_value: 'admin_shop_products',
          feature: 'shop',
          permission: 'hyp.shop.manage.products',
          path: '/admin/shop/products',
        },
        {
          i18n_value: 'admin_shop_categories',
          feature: 'shop',
          permission: 'hyp.shop.manage.categories',
          path: '/admin/shop/categories',
        },
        {
          i18n_value: 'admin_shop_order_export',
          feature: 'shop',
          permission: 'hyp.shop.manage.export',
          path: '/admin/shop/order-export',
        },
      ],
    },
    {
      i18n_value: 'admin_social',
      path: '/admin/social',
      children: [
        {
          i18n_value: 'admin_social_parameters',
          permissionsOR: ['hyp.social.self.manage', 'hyp.social.manage'],
          featuresOR: ['social_pub', 'social_ads', 'social_msg', 'social_stats'],
          path: '/admin/social/parameters',
        },
        {
          i18n_value: 'admin_social_post_templates',
          feature: 'social_pub',
          path: '/admin/social/post-templates?tab=tags',
          permission: 'hyp.social.publish.templates.manage',
        },
        {
          i18n_value: 'admin_social_amplify',
          feature: 'social_ads',
          path: '/admin/social/amplify',
          permission: 'hyp.social.amplify.manage',
        },
        {
          i18n_value: 'admin_social_milestones',
          featuresOR: ['social_pub', 'social_ads', 'social_msg', 'social_stats'],
          path: '/admin/social/milestones',
          permission: 'hyp.social.manage',
        },
        {
          i18n_value: 'admin_social_hashtags',
          featuresOR: ['social_pub', 'social_ads', 'social_msg', 'social_stats'],
          path: '/admin/social/hashtags',
          permission: 'hyp.social.manage',
        },
        {
          i18n_value: 'admin_social_blacklist',
          featuresOR: ['social_pub', 'social_ads', 'social_msg', 'social_stats'],
          path: '/admin/social/blacklist',
          permission: 'hyp.social.manage',
        },
        {
          i18n_value: 'admin_social_answers_templates',
          featuresOR: ['social_answer_template'],
          path: '/admin/social/answers-templates',
          permission: 'hyp.social.converse.answers.templates',
        },
      ],
    },
  ]),
};

export const actions = AdditionalNavigation.actions([
  {
    i18n_value: 'cart',
    icon: <BsCart className="text-xl" />,
    path: '/cart',
    feature: 'shop',
  },
  {
    i18n_value: 'help',
    icon: <BsQuestionCircle className="text-xl" />,
    path: '/help',
  },
  {
    i18n_value: 'user_profile',
    icon: <BsPerson className="text-xl" />,
    path: '/my-account/personal-informations',
    active_paths: ['/mon-compte'],
    hide_on_xs: true,
  },
  adminAction,
]);

export function checkFeatureForNavigation(nav, clientFeatures) {
  if (nav.feature && !clientFeatures.includes(nav.feature)) return false;

  if (nav.featuresAND && nav.featuresAND.some((feature) => !clientFeatures.includes(feature))) {
    return false;
  }

  if (nav.featuresOR && nav.featuresOR.every((feature) => !clientFeatures.includes(feature))) {
    return false;
  }

  return true;
}

export function checkPermissionForNavigation(nav, permissions) {
  if (nav.permission && !permissions.includes(nav.permission)) return false;

  if (
    nav.permissionsOR &&
    nav.permissionsOR.every((permission) => !permissions.includes(permission))
  ) {
    return false;
  }

  return true;
}

const getOnClickPath = (navigationsElements) => (permissions, features) => {
  let firstFound;

  const recursiveFind = (searchIn) => {
    if (searchIn.children) return searchIn.children.find((child) => recursiveFind(child));
    const isPermissionAllowed = checkPermissionForNavigation(searchIn, permissions);
    const isFeatureAllowed = checkFeatureForNavigation(searchIn, features);

    const isAllowed = isFeatureAllowed && isPermissionAllowed;
    if (isAllowed) firstFound = searchIn;

    return isAllowed;
  };

  recursiveFind(navigationsElements);
  if (firstFound) return firstFound.path;

  return false;
};

adminAction.getOnClickPath = getOnClickPath(adminAction);
statisticsPages.getOnClickPath = getOnClickPath(statisticsPages);

function flatten(into, routes) {
  if (routes == null) return into;
  if (Array.isArray(routes)) return routes.reduce(flatten, into);
  const { children, ...route } = routes;
  // unshift is important: children route will be before parent routes
  // usefull for fiding children before parent based on current url.
  into.unshift(route);

  return flatten(into, children);
}

export const allRoutes = flatten([], [...navigation, ...actions]);
