import React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { BreadcrumbProperties } from './breadcrumb.types';

/**
 *
 * A Breadcrumb component
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=108%3A1826
 */
export const Breadcrumb = ({
  homeLink = '/',
  fullWidth = false,
  contained = false,
  paths = [],
  className,
}: BreadcrumbProperties): JSX.Element => {
  const router = useRouter();
  const { asPath } = router;
  const isLibraryRoute = asPath.startsWith('/library');

  return (
    <nav
      className={clsx('flex font-body', className, fullWidth && 'w-full')}
      aria-label="Breadcrumb"
    >
      <ol
        role="list"
        className={clsx(
          'no-scrollbar flex snap-x space-x-4 overflow-scroll [&>*]:snap-start [&>:first-child]:pl-6 [&>:last-child]:pr-6',
          contained && 'rounded-md bg-white shadow',
          fullWidth && 'w-full',
        )}
      >
        <li className="flex">
          <div className="flex items-center">
            <a href={homeLink} className="text-slate-400 hover:text-slate-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>

        {paths.map((path, index) => {
          return (
            <li key={path.name} className="flex">
              <div className="flex items-center">
                {index < paths.length &&
                  (contained ? (
                    <svg
                      className="h-full w-6 flex-shrink-0 text-slate-200"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-slate-400"
                      aria-hidden="true"
                    />
                  ))}
                {isLibraryRoute && index === 1 ? (
                  <span className="ml-4 truncate text-sm font-medium text-slate-500">
                    {path.name}
                  </span>
                ) : (
                  <a
                    href={path.link}
                    className="ml-4 truncate text-sm font-medium text-slate-500 hover:text-slate-700"
                  >
                    {path.name}
                  </a>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
